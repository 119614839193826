import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Bg from '../images/NotFoundBg.svg';
import {
  mobileL, mobileM, mobileS, tablet,
} from '../responsive';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  font-family: oswald;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
const Title = styled.h1`
  font-size: 3.5em;
  color: #1d9682;
`;
const SubTitle = styled.p`
  font-size: 2em;
  color: #1d9682;
  text-align: center;
`;
const Img = styled.img`
  width: 80%;
  height: 50vh;
`;
const HomeBtn = styled(Link)`
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  /* box-shadow: 2px 4px 25px rgb(0 0 0 / 25%); */
  border-radius: 10px;
  white-space: nowrap;
  color: #1d9682;
  font-size: 20px;
  outline: 2.5px solid #1d9682;
  border: none;
  cursor: pointer;
  padding: 14px 40px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: "center";
  ${mobileS({ padding: '10px 100px' })}
  ${mobileM({ padding: '10px 125px' })}
  ${mobileL({ padding: '10px 150px' })}
  ${tablet({ padding: '10px 50px' })}
  &:hover {
    transition: all 0.2sease-in-out;
    background: #ffffffa3;
    color: #1d9682;
  }
`;
function NotFoundPage() {
  return (
    <Container>
      <Wrapper>
        <Img src={Bg} />
        <Title>404: Not Found</Title>
        <SubTitle>
          You just hit a route that doesnt exist... <br />
          the sadness. Call 0792 640 973
        </SubTitle>
        <HomeBtn to="/">Go back Home</HomeBtn>
      </Wrapper>
    </Container>
  );
}

export default NotFoundPage;
